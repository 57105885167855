import {DoiData} from "@/models/DoiData";

export class JournalMetadata {
  readonly language: string | null;
  readonly fullTitle: string | null;
  readonly abbrevTitle: string | null;
  readonly doiData: DoiData | null;

  constructor(
    language: string | null = null,
    fullTitle: string | null = null,
    abbrevTitle: string | null = null,
    doiData: DoiData | null = null
  ) {
    this.language = language;
    this.fullTitle = fullTitle;
    this.abbrevTitle = abbrevTitle;
    this.doiData = doiData;
  }
}
