import { computed, readonly, ref } from 'vue';
import type Keycloak from 'keycloak-js';
import type { KeycloakLogoutOptions, KeycloakTokenParsed } from 'keycloak-js';
import { env } from '@/env';

let initResolve: (value: Keycloak) => void;
let initReject: (reason?: any) => void;

const ready = ref(false);
const instance = new Promise<Keycloak>((resolve, reject) => {
  initResolve = resolve;
  initReject = reject;
});

const logoutOptions: KeycloakLogoutOptions = {
  redirectUri:
    typeof window !== 'undefined' ? window.location.origin : env().BASE_URL,
};
const logout = () =>
  instance.then((instance) => instance.logout(logoutOptions));

const setReady = () => (ready.value = true);
const isReady = readonly(ready);
const resolveInstance = (instance: Keycloak) => initResolve(instance);
const rejectInstance = (error: Error) => initReject(error);

const tokenParsed = ref<KeycloakTokenParsed | null>(null);

const userId = computed(() => tokenParsed.value?.sub || null);
const userMail = computed(() => tokenParsed.value?.email || null);
const userRoles = computed(
  () =>
    tokenParsed.value?.realm_access?.roles.filter((role) =>
      role.match(/^ROLE_/)
    ) || []
);
// const userRole = computed(() => tokenParsed.value.)

instance.then((instance) => {
  tokenParsed.value = instance.tokenParsed || null;
});

export const useToken = () => ({
  userId,
  userMail,
  userRoles,
  tokenParsed: readonly(tokenParsed),
});

const updatedInstance = () => {
  instance.then((instance) => {
    tokenParsed.value = instance.tokenParsed || null;
  });
};

export const useKeycloak = () => {
  return {
    setReady,
    isReady,
    resolveInstance,
    rejectInstance,
    updatedInstance,
    instance,
    logout,
  };
};
