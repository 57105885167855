import { Item } from '@/itemTree/Item';
import { Identifier } from '@/itemTree/Identifier';

export class Relationship {
  readonly relationshipType: string;
  readonly object: Item;
  readonly assertedBy?: Identifier;
  readonly assertedAt?: Date;

  constructor(
    relationshipType: string,
    object: Item,
    assertedBy?: Identifier,
    assertedAt?: Date
  ) {
    this.relationshipType = relationshipType;
    this.object = object;
    this.assertedBy = assertedBy;
    this.assertedAt = assertedAt;
  }

  withItem(newItem: Item): Relationship {
    return new Relationship(
      this.relationshipType,
      newItem,
      this.assertedBy,
      this.assertedAt
    );
  }

  public toJSON() {
    // Custom serialization logic
    return {
      'relationship-type': this.relationshipType,
      object: this.object,
      assertedBy: this.assertedBy,
      assertedAt: this.assertedAt ? this.assertedAt.toISOString() : undefined,
    };
  }
}
