<template>
  <LoginBoxCard :loading="loading">
    <template #title>Crossref Login</template>
    <template #body>
      <!--
      Triggered on receipt of the authLoginFormSubmit event from the user credentials form
      @event authLoginFormSubmit
      -->
      <LoginBoxFormUserCredentials
        v-model="isLoginFormValid"
        :error-message="errorMessage"
        @authLoginFormSubmit="onAuthLoginFormSubmit"
      />
    </template>
  </LoginBoxCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
/**
 * Displays the form to capture username and password
 */
import LoginBoxCard from '@/components/login-box/LoginBoxCard.vue';
import LoginBoxFormUserCredentials from '@/components/login-box/LoginBoxFormUserCredentials.vue';
export default defineComponent({
  name: 'LoginBoxStepAuthenticate',
  components: { LoginBoxFormUserCredentials, LoginBoxCard },
  props: {
    /**
     * Whether the loading bar should be shown
     */
    loading: {
      type: Boolean,
    },
    /**
     * The error message to display, if any
     */
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoginFormValid: false,
    };
  },
  methods: {
    /**
     * Called when the authLoginFormSubmit event is received from the user crendentials form
     * @param payload {object} the username and password
     */
    onAuthLoginFormSubmit(payload: any) {
      this.$emit('authLoginFormSubmit', payload);
    },
  },
});
</script>

<style scoped>

</style>
