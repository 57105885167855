import { KeycloakInstance, KeycloakLoginOptions } from 'keycloak-js';
import { env } from '@/env';
import { computed, ComputedRef, readonly, ref, Ref } from 'vue';
import { useKeycloak, useToken } from './useKeycloak';

export interface UserInfo {
  id: ComputedRef<string | null>;
  mail: ComputedRef<string | null>;
  roles: ComputedRef<string[] | null>;
  isStaff: ComputedRef<boolean>;
}

interface Auth {
  ready: Readonly<Ref<boolean>>;
  initialized: Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<string | null>;
  login: () => Promise<void>;
}

const login = (instance: KeycloakInstance, options?: KeycloakLoginOptions) => {
  const loginUrl = instance.createLoginUrl?.(options);
  if (!loginUrl) {
    console.error('A keycloak login URL could not be generated.');
    return;
  }
  window.location.replace(loginUrl);
};

export const useAuth = (): Auth => {
  if (env().isKeycloakEnabled) {
    const keycloak = useKeycloak();
    return {
      ready: keycloak.isReady,
      initialized: keycloak.instance.then(() => undefined),
      logout: keycloak.logout,
      getToken: () =>
        keycloak.instance.then((instance) => instance?.token || null),
      login: (options?: KeycloakLoginOptions) =>
        keycloak.instance.then((instance) => login(instance, options)),
    };
  }
  return {
    ready: readonly(ref(true)),
    initialized: Promise.resolve(),
    logout: () => Promise.resolve(),
    getToken: () => Promise.resolve('authDisabled'),
    login: () => Promise.resolve(),
  };
};

export const useUserInfo = (): UserInfo => {
  if (env().isKeycloakEnabled) {
    const { userId, userMail, userRoles } = useToken();
    const isStaff = computed(() => userRoles.value.includes('ROLE_STAFF'));
    return { id: userId, mail: userMail, roles: userRoles, isStaff: isStaff };
  }
  return {
    id: computed(() => 'auth-disabled'),
    mail: computed(() => 'auth-disabled@crossref.test'),
    roles: computed(() => []),
    isStaff: computed(() => false),
  };
};
