// src/stores/dialogs.ts
import {defineStore} from 'pinia';
import {URL_DOCS_ROOT} from "@/constants/urls";

export const useAppStore = defineStore('app', {
  state: () => ({
    getHelpUrl: URL_DOCS_ROOT
  }),
  actions: {
    resetGetHelpUrl() {
      this.getHelpUrl = URL_DOCS_ROOT
    }
  }
});
