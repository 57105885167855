import { NewRecord } from '@/statemachines/create-record.machine';
import Ajv from 'ajv';
import { schema as payloadSchema } from '@/schema/ContentRegistrationRecordPayloadSchema';
import {
  ContentRegistrationFormPayload,
  ContentRegistrationFormProps,
  ContentRegistrationFormSchema, ContentRegistrationType,
  LegacyUserCredentials, UserInfo,
} from '@/common/types';
import { saveAs } from 'file-saver';
import some from 'lodash/some'
import isObject from 'lodash/isObject'
import get from 'lodash/get';
import isEmpty from "lodash/isEmpty";
import {
  EMAIL_GENERIC_CONTENT_REGISTRATION_GRANT,
  EMAIL_GENERIC_CONTENT_REGISTRATION_JOURNAL_ARTICLE, EMAIL_GENERIC_CONTENT_REGISTRATION_UKNOWN_RECORD_TYPE
} from "@/constants/contact";

/**
 * Generates a filename for a new record based on its name and extension.
 *
 * @param record - The `NewRecord` object containing the record's name and extension.
 * @returns A string representing the filename.
 */
export const createRecordFilename = (record: NewRecord) => {
  return `${record.recordName}.${record.recordExtension}`;
};

/**
 * Reads an uploaded file as text.
 *
 * @param inputFile - The file to be read.
 * @returns A promise that resolves to the file's text content or rejects with a DOMException.
 */
export const readUploadedFileAsText = (inputFile: File): Promise<string | ArrayBuffer | null> => {
  const temporaryFileReader: FileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

/**
 * Loads and validates a record from an uploaded file.
 *
 * @param file - The file containing the record data.
 * @returns A promise that resolves to the parsed and validated content registration form payload.
 * @throws Error if there is an issue reading the file or if the file's contents do not validate against the schema.
 */
export const loadRecordFromFile = async (file: File) => {
  const fileAsText = await readUploadedFileAsText(file);
  if (typeof fileAsText !== 'string') {
    throw new Error('There was an error reading your file, please try again.');
  }
  const record: ContentRegistrationFormPayload = JSON.parse(fileAsText);

  const ajv = new Ajv();
  const validate = ajv.compile(payloadSchema);
  const valid = validate(record);
  if (!valid) {
    throw new Error('There was an error validating your file, please check and try again');
  }

  return record;
};

/**
 * Constructs a user role credential string from legacy user credentials.
 *
 * @param credentials - The legacy user credentials.
 * @returns A string representing the user and role credentials.
 */
export const getUserRoleCredential = (credentials: LegacyUserCredentials): string => {
  return `${credentials.usr}/${credentials.role}`;
};

/**
 * Returns the generic email address for deposits by users logged in with role credentials.
 */
export const getGenericEmailForRecordType = (type: ContentRegistrationType) => {
  switch (type) {
    case ContentRegistrationType.Grant:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_GRANT;
    case ContentRegistrationType.JournalArticle:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_JOURNAL_ARTICLE;
    default:
      return EMAIL_GENERIC_CONTENT_REGISTRATION_UKNOWN_RECORD_TYPE;
  }
}

/**
 * Constructs a UserInfo object containing depositor name and email
 */
export const getDepositorInfo = (credentials: LegacyUserCredentials, recordType: ContentRegistrationType): UserInfo => {
  return {
    depositorName: getUserRoleCredential(credentials),
    depositorEmail: credentials.usr.includes('@') ? credentials.usr : getGenericEmailForRecordType(recordType)
  }
}

/**
 * Initiates the download of a content registration form record as a JSON file.
 *
 * @param record - The content registration form payload to download.
 */
export const downloadContentRegistrationRecord = (record: ContentRegistrationFormPayload): void => {
  const blob = new Blob([JSON.stringify(record)], {
    type: 'text/plain;charset=utf-8',
  });
  saveAs(blob, record.fileName);
};

/**
 * Transforms content registration form props and schema into a payload suitable for downloading.
 *
 * @param props - The content registration form properties.
 * @param schema - The content registration form schema.
 * @returns A payload object ready for downloading.
 */
export const transformContentRegistrationPropsToFormPayload = (
  props: ContentRegistrationFormProps,
  schema: ContentRegistrationFormSchema
): ContentRegistrationFormPayload => {
  const payload: ContentRegistrationFormPayload = {
    recordName: props.recordName,
    recordType: props.recordType,
    formData: props.formData,
    fileName: createRecordFilename({
      recordExtension: 'json',
      recordName: props.recordName,
      recordType: props.recordType,
    }),
    schemaName: schema.title,
    schemaVersion: schema.version,
    recordExtension: 'json',
  };

  return payload;
};

/**
 * Checks if a given value is a non-empty string.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a non-empty string, otherwise `false`.
 */
export const isNotEmptyString = (value: any): value is string => {
  return typeof value === 'string' && value.trim().length > 0;
};

// Utility for extracting data with safety
export function extractWithDefault<T>(path: string, object: any, defaultValue: T): T {
  return get(object, path, defaultValue);
}

// Try to extract a value at `path` from `object`, or return `null` if not found or the path is undefined
export function getOrNull<T>(object, path: string) {
  return extractWithDefault(path, object, null);
}

export const hasTruthyOrNonEmpty = (values: Array<any>): boolean => {
  return values.some((value) => (Array.isArray(value) ? value.length > 0 : !!value));
};

export const hasAtLeastOnePropertyWithValue = (obj: Record<string, any>): boolean => {
  // Get all the keys of the object
  const keys = Object.keys(obj);

  // Check if there's at least one key and its value is not null/undefined/empty
  return keys.some(key => obj[key] != null && obj[key] !== '');
}

export const hasPropertyWithValue = (obj: any, propertyPath: string): boolean => {
  // Use _.get to safely access the property
  const value = get(obj, propertyPath);

  // Check if the value is not null/undefined/empty
  return value != null && value !== '';
}

export const nestedHasValue = (obj) => {
  // Check if the value is an array or object, and it's not empty
  if ((Array.isArray(obj) && isEmpty(obj)) || (isObject(obj) && isEmpty(obj))) {
    return false;
  }

  // Recursively check each property or element
  return some(obj, (value) => {
    if (Array.isArray(value) || isObject(value)) {
      return nestedHasValue(value); // Recursion for nested objects or arrays
    }
    return !!value; // Check for truthy values
  });
};

/**
 * Recursively checks if an object or array contains any primitive values.
 * A primitive value is considered to be a non-object and non-array value that is not undefined or null.
 *
 * @param obj - The object or array to check for primitive values.
 * @returns true if any primitive value is found, false otherwise.
 */
export const containsPrimitiveValue = (obj: any): boolean => {
  if (Array.isArray(obj)) {
    // Iterate over array and check each element
    return obj.some(element => containsPrimitiveValue(element));
  } else if (isObject(obj)) {
    // Iterate over object properties and check each value
    return Object.values(obj).some(value => containsPrimitiveValue(value));
  } else {
    // Check if the value is a primitive (not undefined or null and not an empty string)
    return obj !== undefined && obj !== null && obj !== '';
  }
};

/**
 * Transforms the ORCID ID input to always include https://orcid.org/
 *
 * @param input - The ORCID ID input string.
 * @returns The transformed ORCID ID string.
 */
export const transformOrcidInput = (input: string): string => {
  const cleanedInput = input.trim();
  const orcidPattern = /^\d{4}-\d{4}-\d{4}-\d{3}[0-9X]$/;

  if (orcidPattern.test(cleanedInput)) {
    return `https://orcid.org/${cleanedInput}`;
  }
  return cleanedInput;
};

/**
 * Transforms the DOI input to strip the domain and return just the DOI.
 *
 * @param input - The DOI input string.
 * @returns The transformed DOI string.
 */
export const formatDoiInput = (input: string): string => {
  const cleanedInput = input.trim();
  const doiUrlPattern = /^https?:\/\/(dx\.)?doi\.org\/(10\.\d{4,9}\/[-._;()\/a-zA-Z0-9]+)$/i;

  const match = doiUrlPattern.exec(cleanedInput);
  if (match) {
    return match[2];
  }

  return cleanedInput;
};

export const autoFormatISSN = (input: string): string => {
  const cleanedInput = input.trim();
  console.log('auto format issn', input)
  // Check if the input is exactly 8 digits
  if (/^\d{8}$/.test(cleanedInput)) {
    return `${cleanedInput.slice(0, 4)}-${cleanedInput.slice(4)}`;
  } else {
    return input; // Return the original input if it's not a valid ISSN
  }
};

