import {Item} from "@/itemTree/Item";

export class ItemTree {
  readonly root: Item;

  constructor(root: Item) {
    this.root = root;
  }

  // Method to update the root item of the tree
  withRoot(newRoot: Item): ItemTree {
    return new ItemTree(newRoot);
  }
}
