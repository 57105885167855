import { Identifier } from '@/itemTree/Identifier';

export class Properties {
  readonly values: Record<string, any>;
  readonly assertedBy?: Identifier;
  readonly assertedAt?: Date;

  constructor(
    values: Record<string, any>,
    assertedBy?: Identifier,
    assertedAt?: Date
  ) {
    this.values = values;
    this.assertedBy = assertedBy;
    this.assertedAt = assertedAt;
  }

  // Method to create a new instance with some properties updated
  withUpdates(updates: {
    values?: Record<string, any>;
    assertedBy?: Identifier;
    assertedAt?: Date;
  }): Properties {
    return new Properties(
      updates.values ? { ...this.values, ...updates.values } : this.values,
      updates.assertedBy !== undefined ? updates.assertedBy : this.assertedBy,
      updates.assertedAt !== undefined ? updates.assertedAt : this.assertedAt
    );
  }
}
