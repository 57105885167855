<template>
  <v-btn variant="outlined" color="primary" @click.prevent="onClick">
    Cancel
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Renders the 'cancel' button
 */
export default defineComponent({
  name: 'LoginBoxActionButtonCancel',
  methods: {
    onClick() {
      const authCancelEvent = new CustomEvent('authCancel');
      window.dispatchEvent(authCancelEvent);
    },
  },
});
</script>

<style scoped>

</style>
