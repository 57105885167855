import { Relationship } from '@/itemTree/Relationships';
import {
  mapContributorRoleToRelType,
  RelationshipType,
} from '@/itemTree/RelationshipType';
import { Item, ItemSubType, ItemType } from '@/itemTree/Item';
import { Properties } from '@/itemTree/Properties';
import { Contributor, ContributorType } from '@/models/Contributor';
import { Affiliation } from '@/models/Affiliation';
import { Identifier } from '@/itemTree/Identifier';
import { WebResource } from '@/models/WebResource';
import { AwardType, FunderType, FundingAward } from '@/models/FundingAward';
import { Issn } from '@/models/Issn';
import { JournalMetadata } from '@/models/JournalMetadata';

/**
 * Creates a relationship for an ISSN, encapsulating it within an `Identifier`.
 * @param issn - The `Issn` instance to create the relationship for.
 * @returns A new `Relationship` instance of type `Issn` with the specified ISSN information.
 */
export const createIssnRelationship = (issn: Issn) => {
  const ids = [new Identifier(issn.toUri())];
  const props = [
    new Properties({
      type: ItemType.Id,
      subtype: ItemSubType.Issn,
      kind: issn.kind,
      original: issn.id,
    }),
  ];
  const obj = new Item(ids, props, []);
  return new Relationship(RelationshipType.Issn, obj);
};

export const createJournalAncestorRelationship = (JournalMetadata) => {
  const props = [
    new Properties({
      type: ItemType.Work,
      subtype: ItemSubType.Journal,
      'title-short': null,
    }),
  ];
};

/**
 * Constructs a resource resolution relationship for a given web resource.
 * @param resource - The web resource to create the relationship for.
 * @returns A new `Relationship` instance of type `ResourceResolution`, associated with the given resource.
 */
export const createResourceResolutionRelationship = (resource: WebResource) => {
  const ids = [new Identifier(resource.url)];
  const props = [
    new Properties({
      type: 'resolution',
      subtype: 'primary',
    }),
  ];
  const rels = [createObjectRelationship(resource)];
  const obj = new Item([], props, rels);
  return new Relationship(RelationshipType.ResourceResolution, obj);
};

/**
 * Builds an ancestor relationship for journal metadata, incorporating ISSN and resource resolution relationships if applicable.
 * @param metadata - The journal metadata, possibly containing DOI and title information.
 * @param issnRels - An array of ISSN relationships to include.
 * @returns A new `Relationship` instance of type `Ancestor` based on the provided journal metadata and ISSN relationships.
 */
export const createAncestorRelationship = (
  metadata: JournalMetadata | null,
  issnRels: Relationship[]
) => {
  const ids = metadata?.doiData
    ? [new Identifier(metadata?.doiData.toDoiUri())]
    : [];
  const props = [
    new Properties({
      type: ItemType.Work,
      subtype: ItemSubType.Journal,
      'title-long': metadata?.fullTitle ? [{ value: metadata?.fullTitle }] : [],
      'title-short': metadata?.abbrevTitle
        ? [{ value: metadata?.abbrevTitle }]
        : [],
    }),
  ];
  const resourceRel = metadata?.doiData?.resource
    ? [
        createResourceResolutionRelationship(
          new WebResource(metadata.doiData.resource)
        ),
      ]
    : [];
  const rels = [...issnRels, ...resourceRel];
  const obj = new Item(ids, props, rels);
  return new Relationship(RelationshipType.Ancestor, obj);
};

/**
 * Generates an affiliation relationship based on the provided affiliation data, identifying the organization by ROR if available.
 * @param affiliation - The `Affiliation` instance containing organization details.
 * @returns A new `Relationship` of type `Affiliation`, encapsulating the affiliation information.
 */
export const createAffiliationRelationship = (affilation: Affiliation) => {
  const ids = !affilation.ror ? [] : [new Identifier(affilation.ror)];
  const props = [
    new Properties({
      type: 'org',
      name: affilation.institution,
    }),
  ];
  const obj = new Item(ids, props, []);
  return new Relationship(RelationshipType.Affiliation, obj);
};

/**
 * Constructs a relationship for a contributor, detailing their role, name, and associated affiliation.
 * @param contributor - The `Contributor` instance to base the relationship on.
 * @returns A new `Relationship` representing the contributor's role and details, including any affiliations.
 */
export const createContributorRelationship = (contributor: Contributor) => {
  const ids: Identifier[] = !contributor.orcid
    ? []
    : [new Identifier(contributor.orcid)];
  const props = [
    new Properties({
      type: ContributorType.Person,
      'first-name': contributor.givenName,
      'last-name': contributor.familyName,
      sequence: contributor.sequence,
      suffix: contributor.suffix,
    }),
  ];
  // create the affiliation relationship if applicable
  const rels = !contributor.affiliation
    ? []
    : [createAffiliationRelationship(contributor.affiliation)];

  const obj = new Item(ids, props, rels);
  const relType = mapContributorRoleToRelType(contributor.role);

  return new Relationship(relType, obj);
};

const createAwardRelationship = (awardNumber: string) => {
  const ids = [new Identifier(awardNumber)];
  const props = [
    new Properties({
      type: AwardType.Grant,
    }),
  ];
  const obj = new Item(ids, props, []);
  return new Relationship(RelationshipType.Awarded, obj);
};

/**
 * Creates a relationship for a funding award, detailing the funder's information and associated award.
 * @param award - The `FundingAward` instance with details about the funder and award.
 * @returns A new `Relationship` of type `Funder`, encompassing the funding award details.
 */
export const createFundingAwardRelationship = (award: FundingAward) => {
  const ids: Identifier[] = !award.funderId
    ? []
    : [new Identifier(award.funderId)];
  const props = [
    new Properties({
      type: FunderType.Org,
      name: award.funderName,
    }),
  ];
  // create the awarded relationship if applicable
  const rels = !award.awardNumber
    ? []
    : [createAwardRelationship(award.awardNumber)];
  const item = new Item(ids, props, rels);
  return new Relationship(RelationshipType.Funder, item);
};

/**
 * Establishes a license relationship using the provided license URL.
 * @param licenseUrl - The URL of the license to relate to.
 * @returns A new `Relationship` of type `License`, associated with the provided URL.
 */
export const createLicenseRelationship = (licenseUrl: string) => {
  const props = {
    type: 'url',
    value: licenseUrl,
  };

  return new Relationship(
    RelationshipType.License,
    new Item([], [new Properties(props)], [])
  );
};

/**
 * Forms an object relationship for a given web resource, utilizing its URL.
 * @param webResource - The `WebResource` to create the relationship for.
 * @returns A new `Relationship` of type `Object`, linked to the specified web resource.
 */
export const createObjectRelationship = (webResource: WebResource) => {
  const ids = [new Identifier(webResource.url)];
  const props = [new Properties({ type: 'web-resource' })];
  const obj = new Item(ids, props, []);
  return new Relationship(RelationshipType.Object, obj);
};
