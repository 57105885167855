import {
  LegacyRoleAuthorisedEvent,
  LegacyUserCredentials,
} from '@/common/types';
import { ALERT_LEVEL_INFO, ALERT_LEVEL_SUCCESS } from '@/constants';
import { inject, InjectionKey, provide } from 'vue';
import { createMachine, InterpreterFrom, assign } from 'xstate';
import { useActor, useInterpret } from '@xstate/vue';
import { sendParent } from 'xstate/lib/actions';
import { useInspector } from './utils';

type CredentialsContext = {
  credentials?: LegacyUserCredentials;
};

export const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGEBOkwDsAuBLAhgDawAEAsvgMYAWumYAdLhIWAMQCqACgCICCAFQCiAfWQAlITyEA5AQEk+AGQDKiUAAcA9rFx4tmdSAAeiACwAGAOwMAnAEYAbGYBMAZhdX7AVjMOANCAAnogAtC4Mjk7ejt5uPp5m9hY+AL6pgWgYOATE5FS09Ews7MhKQnziYpLScoqqRtq6+oZIJub2Efa2VlYWZgAcLgPdnY6BIQihA7aR0Y62brZmZo4LA+mZ6BBYeESkFDR0jMysbCoAEgDyAOoiSlcA4vIyIgBCVwAajTp6uAZGUxTHxmBhWZYuToDAZWMxuGYTML2KxuBjJayOFy2CwDbw9ewbDIgLI7HL7fJHIqELRQOhvLTGFTULQAd0wbAez1eKg4yGQQhUajaTT+ALaQPCblRTicmO8eOx1hciIQA1RkMc4JcMRR4LMm2J212uQOBWODGptMw9MZzLZHKeLxEADE+PIlBxJD9mv9WqAJWZwQxvODejCXM4leNgmE3H4GBZvP1Bi5hrr7PqiSTjeTDoVGJa6Qymaz2Rd5NJ7o7Xh9vsLfi1AbHhgmnNC-I43ClXNHJuFMcGkwNnOsnG4rI4DdmyXk8+b0NpUNglDS6CoAK6UShwWBsb2iv3tBDI7wMLt9TGdWy+HUqtUMDUDCzjkYZqLpImYLQ7eBtad7WczSKU4wH3RtxUQJM7F6K9U3BMYYhVUJzwfbUom8JwLFsNVMy2bIANNSkC1Xa1iztQ8RXA-0wjiGwllTTsCXsZEliQnwbEsRZA2heE+kGKcjRnQj8wYBctCXFcrQ3LcdzA30mymDCzylXw+iw3wVmVGMEFsRwwTbCwUm8VMnFcAT8JNCl8zksVqMUqIz1sBj4lfFFbCQ1wIkTZM-HhdwZg-VIgA */
  createMachine(
    {
      predictableActionArguments: true,
      tsTypes: {} as import('./credentials.machine.typegen').Typegen0,
      schema: {
        context: {} as CredentialsContext,
        events: {} as
          | { type: 'SEND_ALERT_MSG'; msg: string }
          | { type: 'UPDATE_CREDENTIALS'; credentials: LegacyUserCredentials }
          | { type: 'LOGIN_SUCCESS'; credentials: LegacyUserCredentials }
          | { type: 'LOGIN_FAILURE' }
          | { type: 'CLEAR_CREDENTIALS' }
          | { type: 'SHOW_LOGIN_BOX' }
          | { type: 'HIDE_LOGIN_BOX' },
      },
      initial: 'idle',
      id: 'Credentials Machine',
      states: {
        idle: {
          on: {
            SEND_ALERT_MSG: {
              actions: 'sendAlertMsg',
            },
            UPDATE_CREDENTIALS: {
              actions: 'updateCredentials',
            },
            CLEAR_CREDENTIALS: {
              actions: 'clearCredentials',
            },
            SHOW_LOGIN_BOX: {
              target: 'loginBoxShown',
            },
          },
        },
        loginBoxShown: {
          invoke: {
            src: 'listenForAuthEvents',
          },
          on: {
            LOGIN_SUCCESS: {
              actions: [
                'updateCredentials',
                'sendLoginSuccessEvent',
              ],
              target: 'idle',
            },
            LOGIN_FAILURE: {
              target: 'idle',
            },
            HIDE_LOGIN_BOX: {
              target: 'idle',
              actions: 'reportLoginBoxClosed',
            },
          },
        },
      },
    },
    {
      actions: {
        alertCredentialsVerified: sendParent({
          type: 'ALERT',
          alert: {
            msg: 'Credentials verified',
            level: ALERT_LEVEL_SUCCESS,
          },
        }),
        reportLoginBoxClosed: sendParent('LOGIN_BOX_CLOSED'),
        sendAlertMsg: sendParent((context, event) => {
          const msg = event.msg || '';
          return {
            type: 'ALERT',
            alert: {
              msg: msg,
              level: ALERT_LEVEL_INFO,
            },
          };
        }),
        updateCredentials: assign((_context, event) => {
          return { credentials: event.credentials };
        }),
        clearCredentials: assign((_context) => {
          return { credentials: undefined };
        }),
        sendLoginSuccessEvent: (context, event) => {
          const ev = new CustomEvent('loginSuccess', {
            detail: {
              usr: event.credentials?.usr,
              pwd: event.credentials?.pwd,
              role: event.credentials?.role,
            },
          });
          window.dispatchEvent(ev);
        },
      },
      services: {
        listenForAuthEvents: (_context, _event) => (send) => {
          const rolesAuthorisedListener = (ev: Event) => {
            const customEvent = ev as CustomEvent<LegacyRoleAuthorisedEvent>;

            send({
              type: 'LOGIN_SUCCESS',
              credentials: {
                usr: customEvent.detail.username,
                pwd: customEvent.detail.password,
                role: customEvent.detail.role,
              },
            });
          };
          const cancelListener = (_ev: Event) => {
            send('HIDE_LOGIN_BOX');
          };
          window.addEventListener(
            'authRoleAuthorised',
            rolesAuthorisedListener
          );
          window.addEventListener('authCancel', cancelListener);
          return () => {
            window.removeEventListener(
              'authRoleAuthorised',
              rolesAuthorisedListener
            );
            window.removeEventListener('authCancel', cancelListener);
          };
        },
      },
    }
  );

export type CredentialsService = InterpreterFrom<typeof machine>;
export const CredentialsServiceSymbol: InjectionKey<CredentialsService> =
  Symbol('credentials.service');

export function getCredentialsService(_options?: Record<string, unknown>) {
  if (process.env.NODE_ENV === 'test') {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const service = interpret(machine, { devTools: useInspector() });
    service.start();
    return service;
  }
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const service = useInterpret(machine, { devTools: useInspector() });
  return service;
}

export function provideCredentialsService(options?: Record<string, unknown>) {
  const service = getCredentialsService(options);
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  provide(CredentialsServiceSymbol, service);

  return service;
}

export function useCredentialsService() {
  const service = inject(CredentialsServiceSymbol);

  if (!service) {
    throw new Error('Credentials service not provided.');
  }

  return useActor(service);
}
