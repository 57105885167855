import { Affiliation, AffiliationData } from '@/models/Affiliation';

export enum ContributorType {
  Person = 'person',
  Org = 'org',
}

export enum ContributorRole {
  FirstAuthor = 'first-author',
  AdditionalAuthor = 'additional-author',
  Editor = 'editor',
  Chair = 'chair',
  Reviewer = 'reviewer',
  ReviewAssistant = 'review-assistant',
  StatsReviewer = 'stats-reviewer',
  ReviewerExternal = 'reviewer-external',
  Reader = 'reader',
  Translator = 'translator',
}

export enum ContributorSequence {
  First = 'first',
  Additional = 'additional',
}

export interface ContributorData {
  givenName?: string;
  familyName?: string;
  suffix?: string;
  affiliation?: AffiliationData;
  orcid?: string;
  role?: string;
  sequence?: string;
  type?: string;
}

export class Contributor {
  readonly givenName: string | null;
  readonly familyName: string | null;
  readonly suffix: string | null;
  readonly affiliation: Affiliation;
  readonly orcid: string | null;
  readonly role: string | null;
  readonly sequence: string | null;
  readonly type: string | null;

  constructor(
    givenName: string | null = null,
    familyName: string | null = null,
    suffix: string | null = null,
    affiliation: Affiliation = new Affiliation(),
    orcid: string | null = null,
    role: string | null = null,
    sequence: string | null = null,
    type: string | null = null
  ) {
    this.givenName = givenName;
    this.familyName = familyName;
    this.suffix = suffix;
    this.affiliation = affiliation;
    this.orcid = orcid;
    this.role = role;
    this.sequence = sequence;
    this.type = type;
  }
}
