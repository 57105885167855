export class Identifier {
  private readonly uri: string;

  constructor(uri: string) {
    this.uri = uri;
  }

  toString(): string {
    return this.uri;
  }

  toJSON(){
    return this.uri;
  }

  getUri(): string {
    return this.uri;
  }
}
