import {DOI_DOT_ORG_PREFIX} from "@/constants/urls";

export class DoiData {
  readonly doi: string | null;
  readonly resource: string | null;

  constructor(doi: string | null = null, resource: string | null = null) {
    this.doi = doi;
    this.resource = resource;
  }

  toDoiUri() {
    return `${DOI_DOT_ORG_PREFIX}${this.doi}`;
  }
}
