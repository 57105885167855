export enum ItemTreeIssnType {
  Print = 'print',
  Online = 'online',
}

export enum IssnType {
  Print = 'print',
  Online = 'electronic',
}

export const CANNONICAL_ISSN_PREFIX = 'https://id.crossref.org/issn/';

export class Issn {
  readonly id: string;
  readonly kind: IssnType;

  constructor(kind: IssnType, issn: string) {
    this.kind = kind;
    this.id = issn;
  }

  toUri() {
    return `${CANNONICAL_ISSN_PREFIX}${this.id}`;
  }
}
