<template>
  <!--
  Triggered on click of login button
  @event sendFormValues
  -->
  <v-form
    v-model="isLoginFormValid"
    data-cy="login-box-form-user-credentials"
    @submit.prevent="sendFormValues"
  >
    <v-card-text>
      <AlertError :error-message="errorMessage" />
      <BaseInputUsername v-model="username" :disabled="false" />
      <BaseInputPassword v-model="password" class="mt-2" />
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="6">
            <LoginBoxActionButtonCancel></LoginBoxActionButtonCancel>
          </v-col>
          <v-col col="6">
            <LoginBoxActionButtonLogin
              :disabled="!isLoginFormValid"
            ></LoginBoxActionButtonLogin>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <ForgotYourPasswordLink />
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
/**
 * The form to capture the user's login credentials
 * The form's model is bound to isLoginFormValid
 */
import BaseInputUsername from '@/components/base/BaseInputUsername.vue';
import BaseInputPassword from '@/components/base/BaseInputPassword.vue';
import LoginBoxActionButtonLogin from '@/components/login-box/LoginBoxActionButtonLogin.vue';
import AlertError from '@/components/login-box/AlertError.vue';
import ForgotYourPasswordLink from '@/components/login-box/ForgotYourPasswordLink.vue';
import LoginBoxActionButtonCancel from '@/components/login-box/LoginBoxActionButtonCancel.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginBoxFormUserCredentials',
  components: {
    LoginBoxActionButtonCancel,
    AlertError,
    ForgotYourPasswordLink,
    LoginBoxActionButtonLogin,
    BaseInputUsername,
    BaseInputPassword,
  },
  props: {
    /**
     * The error message to display, if any
     */
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      username: '',
      password: '',
      /**
       * @model
       */
      isLoginFormValid: false,
    };
  },
  methods: {
    /**
     * Called when a user submits the form
     * @returns {boolean}
     */
    sendFormValues() {
      if (!this.isLoginFormValid) return false;
      const payload = { ...this.$data };
      /**
       * Login form submitted event
       *
       * @property {object} the username and password
       */
      this.$emit('authLoginFormSubmit', payload);
    },
  },
});
</script>

<style scoped>

</style>
