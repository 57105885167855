import useTheme from '@/composable/useTheme';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import IconLogo from "@/components/IconLogo.vue";

const { defaultTheme, themes } = useTheme();

// Define custom icons
export const customIcons = {
  help: 'mdi-help-circle-outline',
  'logo': IconLogo
};

// default Vuetify config
const config = {
  theme: {
    defaultTheme: defaultTheme.value,
    themes: themes.value,
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...customIcons,
    },
  },
  defaults: {
    VDialog: {
      VCardActions: {
        VBtn: {variant: 'text'},
      },
    },
    VCardActions: {
      VBtn: {variant: 'elevated'},
    },
    VStepperActions: {
      VBtn: {variant: 'elevated'},
    }
  }
};

// Extended Vuetify config for use in under test
// Disables transitions and ripple effects globally to reduce test duration
const configUnderTest = {
  ...config,
  defaults: {
    ...config.defaults,
    global: {
      transition: false,
      ripple: false,
    },
  },
};

const vuetifyInstance = createVuetify(config);

export const vuetifyInstanceUnderTest = createVuetify(configUnderTest);
export default vuetifyInstance;
