<template>
  <v-navigation-drawer v-model="localDrawer" temporary>
    <v-list>
      <v-list-item class="py-6 py-xs-2">
        <app-logo-workmark></app-logo-workmark>
      </v-list-item>
      <v-divider></v-divider>
      <menu-item
        v-for="(item, index) in menuItems"
        :key="index"
        :menu-item="item"
      ></menu-item>
    </v-list>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppLogoWorkmark from '@/components/AppLogoWordmark.vue';
import { useRouter } from 'vue-router';
import { useMenuItems } from '@/composable/useMenuItems';
import MenuItem from '@/components/MenuItem.vue';
import { useUserInfo } from '@/composable/useAuth'; // Import the useUserInfo composable

export default defineComponent({
  name: 'NavDrawer',
  components: { AppLogoWorkmark, MenuItem },
  props: {
    value: {
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();
    const routes = [...router.options.routes] || [];
    const { isStaff } = useUserInfo(); // Get the isStaff computed property
    const { menuItems } = useMenuItems(routes, isStaff);
    return { menuItems, isStaff };
  },
  data: (): {
    localDrawer: boolean | undefined;
  } => ({
    localDrawer: false,
  }),
  watch: {
    value: function () {
      this.localDrawer = this.value;
    },
    localDrawer: function () {
      this.$emit('input', this.localDrawer);
    },
  },
});
</script>

<style scoped>

</style>
