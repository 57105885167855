export enum FunderType {
  Org = 'org',
}

export enum AwardType {
  Grant = 'grant',
};

export interface FundingAwardData {
  awardNumber?: string;
  'funder-id'?: string;
  'funder-name'?: string;
}

export class FundingAward {
  readonly awardNumber: string | null;
  readonly funderId: string | null;
  readonly funderName: string | null;

  constructor(
    awardNumber: string | null = null,
    funderId: string | null = null,
    funderName: string | null = null
  ) {
    this.awardNumber = awardNumber;
    this.funderId = funderId;
    this.funderName = funderName;
  }
}
