/**
 * Main entry point for the Vue application.
 * This file sets up the Vue app with necessary plugins, configurations, and services.
 * It includes configurations for i18n, Keycloak authentication, Sentry for error logging,
 * and dynamic routing based on the application's state.
 */

// Composables for authentication and internationalization
import { useAuth } from './composable/useAuth';
import { useKeycloak } from './composable/useKeycloak';
import { useI18next } from './composable/useI18n';

// Vue and related plugins
import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import PortalVue from 'portal-vue';
import VueMatomo from 'vue-matomo';

// Sentry for error tracking
import * as Sentry from '@sentry/vue';
import redact from 'redact-object';

// Keycloak for user authentication
import VueKeycloakJs from '@pvale/vue-keycloak-js';
import Keycloak from 'keycloak-js';
import { VueKeycloakOptions } from '@pvale/vue-keycloak-js/dist/types';

// i18next for internationalization
import I18NextVue from 'i18next-vue';

// XState for state management
import { inspect } from '@xstate/inspect';
import { useInspector } from '@/statemachines/utils';

// Environment configurations
import { env } from '@/env';

// Head management for Vue 3
import { createHead } from '@unhead/vue';

// Additional composables
import { useLoadingWithPromises } from './composable/useLoadingWithPromises';
import { useDynamicRouter } from "@/composable/useDynamicRouter";
import { useAlert } from "@/composable/useAlert";

// Store and state machine setup
import { store } from "@/store";
import { getAppService } from "@/statemachines/app.machine";
import { createPinia } from 'pinia';

const APP_VERSION = import.meta.env.VUE_APP_SENTRY_RELEASE || 'unknown';
console.log(`Running console version ${APP_VERSION}`);

// Initialize Pinia
const pinia = createPinia();

// Initializing Vue application
const app = createApp(App);
app.use(pinia);
const head = createHead();
app.use(head);
// XState inspector initialization for debugging
if (useInspector()) {
  inspect({
    iframe: false,
  });
}

// Setup for loading states with promises
const loadingState = useLoadingWithPromises();
// Initialize and configure the application state machine service
store.appService = getAppService();
// Conditional mock server setup for development
if (import.meta.env.DEV || env().ALLOW_HTTP_MOCKING_FOR_TESTS === 'true') {
  if (env().isHttpMocking) {
    console.log('MOCK');
    import('@/mocks/browser').then((module) => {
      const { worker } = module;
      worker.start({
        onUnhandledRequest: "bypass"
      });
    });
  }
}

// PortalVue setup for rendering components in different parts of the DOM
app.use(PortalVue);

// i18next initialization
const { i18next, initialized: i18nInitialized } = useI18next();
app.use(I18NextVue, { i18next });

/**
 * Initializes Keycloak for user authentication.
 * Configures Keycloak client options and handles the authentication process.
 * Sets up error handling and refresh logic for authentication state.
 */
const keycloak = async () => {
  if (env().isKeycloakEnabled) {
    const keycloakOptions: VueKeycloakOptions = {
      init: {
        // 'check-sso' will authenticate the client only if the user is already logged in
        // 'login-required' will authenticate is already logged in, or re-redirect to the login page
        onLoad: 'check-sso',
        // attempt to check SSO 'silently' via a hidden iframe.
        // blocked 3rd party cookies can prevent this from working, the adapter will fallback to redirects
        checkLoginIframe: false,
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        enableLogging: env().isKeycloakDebug,
        pkceMethod: 'S256',
        responseMode: 'fragment'
      },
      config: {
        url: env().keycloakServerURL,
        clientId: env().keycloakClientId,
        realm: 'crossref',
      },
      onReady(keycloak: Keycloak) {
        keycloakStore.setReady();
        keycloakStore.resolveInstance(keycloak);
        console.debug('*** Keycloak Ready ***');

      },
      onInitError(error: Error, _err: Keycloak.KeycloakError) {
        keycloakStore.rejectInstance(error);
        console.debug('*** Keycloak Init Error ***');
      },
      async onInitSuccess(_authenticated) {
        console.debug('*** Keycloak Init Success ***');
      },
      onAuthRefreshError() {
        console.log('!!! keycloak auth refresh error !!!');
        keycloakStore.updatedInstance();
        auth.login();
      },
      onAuthRefreshSuccess() {
        console.debug('keycloak auth refresh success');
        keycloakStore.updatedInstance();
      },
    };
    const keycloakStore = useKeycloak();
    loadingState.addPromise(keycloakStore.instance);
    const auth = useAuth();
    try{
      app.use(VueKeycloakJs, keycloakOptions);
    }
    catch (e) {
      console.log("VKCJS error")
      throw new Error("An error occurred. Some application features ")
    }
  }
}

// Sentry setup for error logging and tracking
if (env().isSentry) {
  Sentry.init({
    app,
    release: APP_VERSION,
    environment: env().sentryEnvironment,
    dsn: env().sentryDSN,
    /**
     * A high normalizeDepth (eg 10) causes recursion errors where the Sentry SDK
     * tries to serialise objects that are too large, or have circular references.
     * Related to https://github.com/getsentry/sentry-javascript/pull/4487
     */
    normalizeDepth: 3,
    integrations: [
      new Sentry.BrowserTracing({
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'localhost:8000',
      '127.0.0.1:8000',
      /^\//,
    ],
    trackComponents: false,
    beforeSend(event) {
      /**
       * Remove secrets before sending the error event to Sentry.io
       * If a component name contains 'password', or a component has
       * the prop 'type' with the value 'password', the prop 'value'
       * will be redacted
       */
      if (
        typeof event === 'object' &&
        typeof event.contexts === 'object' &&
        typeof event.contexts.vue === 'object' &&
        typeof event.contexts.vue.componentName === 'string'
      ) {
        if (
          event.contexts.vue.componentName.match(/password/i) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /** @ts-ignore */
          (typeof event.contexts.vue.propsData === 'object' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /** @ts-ignore */
            typeof event.contexts.vue.propsData.type === 'string' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /** @ts-ignore */
            event.contexts.vue.propsData.type === 'password')
        ) {
          event.contexts.vue.propsData = redact(event.contexts.vue.propsData, [
            'value',
          ]);
        }
      }

      return event;
    },
  });
}

// Adding Vuetify plugin to the Vue application
app.use(vuetify);

// TODO: Would be nicer to render some intermediate content until the translation files are loaded

/**
 * Starts the Vue application once internationalization (i18n) is ready.
 * Initializes Keycloak, dynamic routing, and mounts the Vue app to the DOM.
 * Handles Keycloak initialization errors and dynamically imports Vue Router.
 */
i18nInitialized.then(async () => {
  if (env().isKeycloakEnabled) {
    try {
      // Initialize Keycloak for authentication
      await keycloak();
      const kc = useKeycloak();


      // Wait for the Keycloak instance to be ready
      await kc.instance;
    }
    catch(e) {
      console.log('keycloak error', e)
    }
  }
  // Dynamically import and initialize the Vue Router
  // This import is delayed until after Keycloak is ready to ensure
  // that the router does not interfere with Keycloak's URL handling.
  const { initializeRouter } = await import('@/router');
  const router = initializeRouter();

  // Resolve the router instance using the dynamic router composable
  // This makes the router instance available throughout the application
  // and signals that it is ready to be used.
  const { resolveRouterInstance } = useDynamicRouter();
  resolveRouterInstance(router);

  // Use the router in the Vue application
  app.use(router);

  // Use Matomo analytics
  app.use(VueMatomo, {
    // Configuration Options
    host: env().MATOMO_INSTANCE_URL,
    siteId: env().MATOMO_SITE_ID,
    router,
  });
  // Mount the Vue application to the DOM
  app.mount('#app');
});
