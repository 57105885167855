import { ItemTree } from '@/itemTree/ItemTree';
import { FormData } from '@/forms/types';
import { Item } from '@/itemTree/Item';
import { Properties } from '@/itemTree/Properties';
import { Identifier } from '@/itemTree/Identifier';
import { Relationship } from '@/itemTree/Relationships';
import * as Util from '@/itemTree/mapping/Util';
import { DOI_DOT_ORG_PREFIX } from '@/constants/urls';

/**
 * Contract to map form data to an `ItemTree` structure.
 */
export interface FormDataToItemTreeMapper extends ItemTreeMapper {
  toItemTree(input: FormData): ItemTree;
}

/**
 * Contract for a generic item tree mapper.
 */
export interface ItemTreeMapper {
  toItemTree(input: any): ItemTree;
}

/**
 * Enumerates paths to data fields within article-related form data.
 */
export enum ArticleDataPath {
  FullTitle = 'article.title',
  Abstract = 'article.abstract',
  DOI = 'article.doi',
  ResourceUrl = 'article.resourceUrl',
  PublicationDates = 'article.publicationDates',
  PublishedInPrint = 'article.publicationDates.publishedInPrint',
  PublishedOnline = 'article.publicationDates.publishedOnline',
  Contributors = 'article.contributors',
  License = 'article.license',
  FundingAwards = 'article.funding',
}

/**
 * Implements the mapping of form data to an `ItemTree` structure for journal articles.
 */
export class JournalArticleMapper implements FormDataToItemTreeMapper {
  /**
   * Converts the provided form data into an `ItemTree` representation.
   * @param form - The form data to map from.
   * @returns An `ItemTree` constructed from the form data.
   */
  toItemTree(form: FormData): ItemTree {
    return new ItemTree(this.journalArticle(form));
  }

  /**
   * Constructs a journal article `Item` from form data.
   * @param form - The form data.
   * @returns An `Item` representing the journal article.
   */
  private journalArticle(form: FormData): Item {
    const props = this.buildProps(form);
    const ids = this.buildIds(form);
    const rels = this.buildRels(form);

    return new Item(ids, props, rels);
  }

  /**
   * Builds properties for the journal article item.
   * @param form - The form data to extract properties from.
   * @returns An array of `Properties` for the item.
   */
  buildProps(form: FormData): Properties[] {
    const props = new Properties({
      'title-long': Util.getFullTitle(form),
      abstract: Util.getAbstract(form),
      'published-print': Util.getDatePublishedInPrint(form),
      'published-online': Util.getDatePublishedOnline(form),
      type: 'work',
      subtype: 'journal-article',
    });

    return [props];
  }

  /**
   * Generates an identifier for the journal article item based on the DOI.
   * @param form - The form data to extract the DOI from.
   * @returns An array of `Identifier` instances.
   */
  buildIds(form: FormData): Identifier[] {
    const ids = [
      new Identifier(`${DOI_DOT_ORG_PREFIX}${Util.getArticleDoi(form)}`),
    ];

    return ids;
  }

  /**
   * Constructs relationships for the journal article item.
   * @param form - The form data to build relationships from.
   * @returns An array of `Relationship` instances.
   */
  buildRels(form: FormData): Relationship[] {
    const rels = [
      ...Util.getLicenseRels(form),
      ...Util.getContributorRelationships(form),
      ...Util.getArticleResourceUrlRelationship(form),
      ...Util.getAncestorRelationship(form),
      ...Util.getFundingRelationships(form),
    ].filter(Boolean) as Relationship[];

    return rels;
  }
}

/**
 * Maps journal article form data to an `ItemTree`.
 * @param form - The journal article form data to be mapped.
 * @returns An `ItemTree` representation of the form data.
 */
export const mapJournalArticleToItemTree = (form: FormData): ItemTree => {
  const mapper = new JournalArticleMapper();
  return mapper.toItemTree(form);
};

export enum JournalDataPath {
  Doi = 'journal.doi',
  Titles = 'journal.titles',
  FullTitle = 'journal.titles.fullTitle',
  AbbrevTitle = 'journal.titles.abbreviatedTitle',
  ResourceUrl = 'journal.resourceUrl',
  OnlineIssn = 'journal.issn.onlineIssn',
  PrintIssn = 'journal.issn.printIssn',
}
