import {
  JournalArticleMapper,
  mapJournalArticleToItemTree,
} from '@/itemTree/mapping/JournalArticleMapper';
import {ContentRegistrationType, UserInfo, XMLGenerationStrategy} from '@/common/types';
import { FormData } from '@/forms/types';
import {env} from "@/env";
import {
  XmlConversionBadInputError,
  XmlConversionError,
  XmlConversionNetworkError,
} from '@/errors';
import {
  EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
  NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN
} from "@/constants/contact";

export class JournalArticleXmlRenderingStrategy
  implements XMLGenerationStrategy
{
  async generateXML(
    recordType: ContentRegistrationType,
    data: FormData,
    prettyPrint = false,
    userInfo?: UserInfo
  ): Promise<string> {
    // Request XML from Manifold
    const itemTree = mapJournalArticleToItemTree(data);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const raw = JSON.stringify({
      itemTree,
      userInfo: {
        doiBatchId: 'batch_000001',
        depositorName:
          userInfo?.depositorName ||
          NAME_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        depositorEmail:
          userInfo?.depositorEmail ||
          EMAIL_CONTENT_REGISTRATION_DEPOSITOR_UNKNOWN,
        registrant: 'content-registration-form',
        timestamp: new Date().getTime(),
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as RequestRedirect,
    };

    try {
      const response = await fetch(env().convertApiEndpoint, requestOptions);
      if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = JSON.stringify(errorResponse, null, 2);
        if (response.status === 400) {
          throw new XmlConversionBadInputError(`${response.statusText}`, {
            code: response.status,
            domainMessage: errorMessage,
          });
        }
        if (response.status === 500) {
          throw new XmlConversionError(response.statusText, {
            code: response.status,
          });
        }
        throw new Error('Unknown error');
      }
      const result = await response.text();
      return result;
    } catch (e) {
      // Determine if the error is a fetch-specific error
      if (e instanceof TypeError && e.message === 'Failed to fetch') {
        throw new XmlConversionNetworkError(e.message);
      } else {
        // Handle other unexpected errors
        throw e;
      }
    }
  }
}
