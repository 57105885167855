export interface AffiliationData {
  institution?: string;
  ror?: string;
}

export class Affiliation {
  readonly institution: string | null;
  readonly ror: string | null;

  constructor(institution: string | null = null, ror: string | null = null) {
    this.institution = institution;
    this.ror = ror;
  }
}
