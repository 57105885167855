/**
 * This class represents and Item. It requires Identifiers, Properties and Relationships.
 * These can be passed into the constructor, or a new object can be requested, with the passed
 * identifers/properties/relationships passed in.
 */
import {Identifier} from '@/itemTree/Identifier';
import {Relationship} from '@/itemTree/Relationships';
import isArray from 'lodash/isArray';
import {Properties} from '@/itemTree/Properties';

export enum ItemType {
  Id = 'id',
  Work = 'work',
  Url = 'url',
  WebResource = 'web-resource',
}

export enum ItemSubType {
  Issn = 'issn',
  Journal = 'journal',
  JournalArticle = 'journal-article',
}

export class Item {
  public readonly identifiers: Identifier[];
  public readonly properties: Properties[];
  public readonly relationships: Relationship[];

  constructor(
    identifiers: Identifier[] = [],
    properties: Properties[] = [],
    relationships: Relationship[] = []
  ) {
    this.identifiers = identifiers;
    this.relationships = relationships;
    this.properties = properties;
  }

  withIdentifiers(identifiers: Identifier[]): Item {
    return new Item(identifiers, this.properties, this.relationships);
  }

  withProperties(properties: Properties[]): Item {
    return new Item(this.identifiers, properties, this.relationships);
  }

  withRelationships(relationships: Relationship[]): Item {
    return new Item(this.identifiers, this.properties, relationships);
  }

  // Method to flatten identifiers
  flattenIdentifiers(): string[] {
    const identifiers: string[] = [];

    // Add the current item's identifiers
    identifiers.push(
      ...this.identifiers.map((identifier) => identifier.toString())
    );

    // Recursively add identifiers from nested items (if any)
    this.relationships.forEach((relationship) => {
      identifiers.push(...relationship.object.flattenIdentifiers());
    });

    return identifiers;
  }

  getPropertyArray(): boolean | null {
    return isArray(this.properties?.[0]) ?? null;
  }

  getPropertyText(
    key: string,
    defaultValue: string | null = null
  ): string | null {
    const propertyValue = this.properties[0]?.values[key];
    if (typeof propertyValue === 'string') {
      return propertyValue;
    }
    return defaultValue;
  }
}
